import {Post} from "../interface/PostInterface";
import { Guide } from "../interface/GuideInterface";

export async function getToken(data: FormData) {
  const res = await fetch(`https://salatiga.shineryskin.com/api/catalog`, {
    method: 'POST',
    body: data
  })
  
  return await res.json()
}

export async function getProducts() {
  const res = await fetch(`https://store.shineryskin.com/api/products`)
  return await res.json()
}

export async function getPosts() : Promise<Post[]>{
  const res = await fetch(`https://store.shineryskin.com/api/instagram/posts`)
  const payload = await res.json()
  return payload.data.map((i: Post) => ({...i, caption: i.caption.replace(/\n/, "<br/><br/><br/>")}))
}

export async function getGuides() : Promise<Guide>{
  const res = await fetch(`https://store.shineryskin.com/api/instagram/guides`)
  return await res.json()
}