const Services = ({ setRef } : any) => {
  return (
    <div className="text-center text-khaki-900 py-10 px-2 md:px-6 lg:px-12 select-none">
      <p className="text-3xl font-semibold pb-6">
        Perawatan
      </p>
      <div className="container-full grid grid-cols-2 lg:grid-cols-3 gap-x-1 xl:gap-x-8 gap-y-1 md:gap-y-2 mx-auto">
        <div className="order-1 rounded-md bg-white hover:bg-khaki-300 transition transform m-2 justify-center p-2 md:p-5 border border-khaki-100/60 shadow-md">
          <p className="text-lg md:text-xl font-semibold py-2 tracking-wide">Facial</p>
          <p className="text-sm md:text-base">Rangkaian perawatan dengan tujuan membersihkan, meremajakan, dan relaksasi.</p>
        </div>
        <div className="order-2 rounded-md bg-white hover:bg-khaki-300 transition transform m-2 justify-center p-2 md:p-5 border border-khaki-100/60 shadow-md">
          <p className="text-lg md:text-xl font-semibold py-2 tracking-wide">Peeling</p>
          <p className="text-sm md:text-base">Tindakan mempercepat lepasnya sel-sel kulit mati sehingga terjadi peremajaan kulit wajah.</p>
        </div>
        <div className="order-5 lg:order-3 col-span-2 lg:col-span-1 row-span-1 lg:row-span-2 m-2 p-2 md:p-5 text-justify">
          <p className="text-lg lg:text-xl font-semibold py-2 tracking-wide">Perawatan lainnya:</p>
          <ul className="list-disc list-outside ml-6 font-medium lg:text-xl">
            <li className="pb-1.5">Intense Pulse Light (IPL)</li>
            <li className="pb-1.5">Dermashine</li>
            <li className="pb-1.5">Mesoshine</li>
            <li className="pb-1.5">Laser Treatments</li>
            <li className="pb-1.5">Premium Treatments</li>
            <li className="pb-1.5">Particular Treatments</li>
            <li className="pb-1.5">Special Package</li>
          </ul>
          {/*<p className="text-xl font-semibold py-2 text-[#ffda56] tracking-wide">Selengkapnya</p>*/}
        </div>
        <div className="order-3 lg:order-4 rounded-md bg-white hover:bg-khaki-300 transition transform m-2 justify-center p-2 md:p-5 border border-khaki-100/60 shadow-md">
          <p className="text-lg md:text-xl font-semibold py-2 tracking-wide">Pengencangan</p>
          <p className="text-sm md:text-base">Stimulasi kolagen di bawah kulit dengan gelombang radiofrekuensi yang dapat memelihara kekenyalan dan kekencangan kulit.</p>
        </div>
        <div className="order-4 lg:order-5 rounded-md bg-white hover:bg-khaki-300 transition transform m-2 justify-center p-2 md:p-5 border border-khaki-100/60 shadow-md">
          <p className="text-lg md:text-xl font-semibold py-2 tracking-wide">Penyinaran</p>
          <p className="text-sm md:text-base">Terapi dengan menggunakan sinar dengan beragam panjang gelombang untuk beragam tujuan.</p>
        </div>
      </div>
      {/*<p className="text-xl lg:hidden text-left font-semibold p-3 text-[#ffda56] tracking-wide">Selengkapnya</p>*/}
      <a ref={setRef} />
    </div>
  )
}

export default Services