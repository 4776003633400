import Hero from "./Hero";
import Services from "./Services";
import Products from "./Products";
import BrandAmb from "./BrandAmb";
import Footer from "./Footer";
import { useRef } from "react";
import Navbar from "../Navbar";
import Posts from "./Posts";
import Guides from "./Guides";

const Main = () => {
  
  const refService = useRef()
  const executeScrollService = () => {
    if(refService.current){
      const target = refService.current as unknown as HTMLElement
      target.scrollIntoView({
        behavior: "smooth"
      })
    }
  }
  
  const refProduct = useRef()
  const executeScrollProduct = () => {
    if(refProduct.current){
      const target = refProduct.current as unknown as HTMLElement
      target.scrollIntoView({
        behavior: "smooth"
      })
    }
  }
  
  return (
    <>
      <Navbar serviceCallback={executeScrollService} productCallback={executeScrollProduct} />
      <div className="pt-20">
        <Hero/>
        <Posts/>
        <Guides setRef={refService}/>
        <Services setRef={refProduct}/>
        <Products/>
        <BrandAmb/>
        <Footer/>
      </div>
    </>
  )
}

export default Main