import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { product } from "./LandingPage/Products";
import { IDRFormat } from "../utils/helper";

interface ItemDetailProps {
  isOpen: boolean,
  item: product,
  closeModal: any
}

const ProductDetail: React.FC<ItemDetailProps> = ({ isOpen, item, closeModal }) => {
  
  let completeButtonRef = useRef(null)
  
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => {
          closeModal()
        }}
        initialFocus={completeButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
      
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center px-8 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {item && <div className="w-full max-w-sm transform overflow-hidden bg-white p-4 rounded-xl text-left align-middle shadow-xl transition-all">
                  <img
                      src={item.photo}
                      alt=''
                      className="aspect-[4/5] object-cover w-full rounded-lg bg-gray-100"
                  />
                  <div className="my-4 space-y-2">
                      <p className="sm:text-lg md:text-2xl font-semibold text-khaki-900 capitalize">{item.name ? item.name : ''}</p>
                      <p className="text-justify text-xs md:text-sm text-khaki-900 max-h-12 md:max-h-16 overflow-y-auto no-scrollbar">{item.description}</p>
                  </div>
                  <div className="flex justify-between mb-4">
                      <p className="text-sm md:text-base font-semibold text-khaki-900 my-auto">{IDRFormat(item.price)}</p>
                  </div>
                  <div className="flex justify-between space-x-2.5 mt-4">
                      <button
                          className="w-full py-2.5 text-center bg-gray-100 text-khaki-900 font-semibold outline-0 rounded-lg"
                          onClick={() => {
                            closeModal()
                          }}
                      >
                          Kembali
                      </button>
                      <button
                          className={"w-full py-2.5 text-center text-white font-bold outline-0 rounded-lg bg-tanGreen-400 tracking-wide"}
                          onClick={() => {
                            window.open(item.link)
                          }}
                      >
                          Beli
                      </button>
                  </div>
              </div> }
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ProductDetail