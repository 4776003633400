// @ts-ignore
export const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  
  return (
    <button
      className="absolute left-0 h-12 w-12 bg-white box-border border rounded-full shadow"
      onClick={() => onClick()}
    >
      <img src="/icon/chevronLeft.svg" className="mx-auto h-6 w-6" alt=""/>
    </button>
  );
};

// @ts-ignore
export const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  
  return (
    <button
      className="absolute right-0 h-12 w-12 bg-white box-border border rounded-full shadow"
      onClick={() => onClick()}
    >
      <img src="/icon/chevronRight.svg" className="mx-auto h-6 w-6" alt=""/>
    </button>
  );
};