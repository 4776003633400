import React, { Fragment, useEffect, useRef, useState } from "react";
import { Guide, GuideItem } from "../interface/GuideInterface";
import { Dialog, Transition } from "@headlessui/react";

interface GuideDetailProps {
  isOpen: boolean;
  item: GuideItem;
  closeModal: any;
}

const GuideDetail: React.FC<GuideDetailProps> = ({isOpen, item, closeModal}) => {
  
  const [guide, setGuide] = useState<GuideItem | null>(null)
  
  const completeButtonRef = useRef(null)
  
  useEffect(() => {
    if (Object.keys(item).length > 0) {
      setGuide(item)
    }
  }, [item])
  
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={() => closeModal()}
        initialFocus={completeButtonRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex fixed h-screen w-full md:w-[600px] right-0">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              {guide && <div className="w-full md:w-[600px] min-h-screen overflow-y-auto no-scrollbar bg-white text-khaki-900 align-middle drop-shadow-2xl transform transition-all">
                  <div className="flex justify-start space-x-2 px-6 pt-6 pb-4 bg-white sticky top-0 z-10">
                      <button className="outline-0" onClick={() => closeModal()}>
                          <img src="/icon/close.svg" className="my-auto h-7 w-7" alt=""/>
                      </button>
                      <p className="text-lg md:text-xl font-semibold tracking-wide line-clamp-1">{guide.title}</p>
                  </div>
                  <p className="px-6 mb-6 text-sm md:text-base">{guide.description}</p>
                  <div className="flex items-center px-6">
                      <div className="flex-grow border border-t"/>
                      <div className="flex-grow-0 mx-5 text-sm font-medium">{guide.num_items} Posts</div>
                      <div className="flex-grow border border-t"/>
                  </div>
                  <div className="p-6 space-y-10">
                    {guide.items.map((item, index) => (
                      <div key={item.code} className="space-y-2.5">
                        <p className="md:text-lg font-medium">{index+1}. {item.title}</p>
                        <p className="text-sm">{item.description}</p>
                        <video className="mx-auto w-full h-96" controls>
                          <source src={item.video.url}/>
                        </video>
                      </div>
                    ))}
                  </div>
              </div>}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default GuideDetail