import { useState } from "react"
import DownloadForm from "./DownloadForm";

const Navbar = ({serviceCallback, productCallback}: any) => {
  
  const [open, setOpen] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  
  return (
    <>
      <nav className="bg-white overflow-hidden fixed z-20 w-full">
        <div className="container-full px-6 lg:px-12 py-5 mx-auto md:flex md:justify-between md:items-center">
          <div className="flex items-center justify-between">
            <div className="flex flex-row items-center">
              <div className="h-10 w-10">
                <a href="/">
                  <img src="/Logo.png" className="rounded-full mx-auto" />
                </a>
              </div>
            </div>
            <div className="flex md:hidden"  onClick={() => setOpen(!open)}>
              <button
                type="button"
                className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                aria-label="toggle menu">
                <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                  <path fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                </svg>
              </button>
            </div>
          </div>
          <div className={`text-sm md:flex ${open? "mt-2" : "hidden"}`}>
            <div className="flex text-left flex-col md:space-x-10 md:flex-row md:ml-6">
              <p className="my-1 text-khaki-900 hover:text-gray-900 hover:bg-gray-100 md:my-0 rounded-xl py-1 px-3 font-semibold cursor-pointer"
                 onClick={() => {
                   serviceCallback()
                   setOpen(false)
                 }}>
                Perawatan
              </p>
              <p className="my-1 text-khaki-900 hover:text-gray-900 hover:bg-gray-100 md:my-0 rounded-xl py-1 px-3 font-semibold cursor-pointer"
                 onClick={() => {
                   productCallback()
                   setOpen(false)
                 }}>
                Produk
              </p>
              <a className="my-1 text-khaki-900 hover:text-gray-900 hover:bg-gray-100 md:my-0 rounded-xl py-1 px-3 font-semibold"
                 href="https://wa.me/6287834888811" target="_blank">
                Reservasi
              </a>
              <p className="my-1 text-khaki-900 hover:text-gray-900 hover:bg-gray-100 md:my-0 rounded-xl py-1 px-3 font-semibold cursor-pointer"
                 onClick={() => {
                   setOpen(false)
                   setFormOpen(true)
                 }}>
                Unduh Katalog
              </p>
            </div>
          </div>
        </div>
      </nav>
      <DownloadForm open={formOpen} setOpen={setFormOpen} />
    </>
  )
}

export default Navbar