import React, {useEffect, useState} from "react";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import {Post} from "../../interface/PostInterface";
import {getPosts} from "../../api/api";
import { CustomLeftArrow, CustomRightArrow } from "../Additional/CarouselArrow";

const responsive = {
    ultraWideDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    wideDesktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1280, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1
    }
};

const Posts = () => {
    const [posts, setPosts] = useState<Post[]>([])
    useEffect(() => {
        getPosts()
            .then(data => setPosts(data))
    },[])

    return (<div className="text-center text-khaki-900 py-10 px-2 md:px-6 lg:px-12 select-none">
        <p className="text-3xl font-semibold pb-6">
            Recent Updates
        </p>
        {posts.length > 0 && <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={true}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable={false}
          customRightArrow={<CustomRightArrow onClick={undefined} />}
          customLeftArrow={<CustomLeftArrow onClick={undefined} />}
        >
            {posts.map((data) => (
              <div key={data.id} className="relative"
                   onClick={() => window.open(`https://www.instagram.com/p/${data.shortcode}`, "_blank")}>
                  <img
                    key={data.id}
                    src={data.thumbnail}
                    className="mx-auto w-72 h-72 border rounded-md object-cover"
                    alt=""
                    draggable={false}
                    crossOrigin="anonymous"
                  />
                  <div
                    className="absolute inset-0 mx-auto w-72 h-72 p-2 hover:bg-black/80 text-sm text-transparent hover:text-white rounded-md transform transition-all duration-500">
                      <div className="flex justify-around mt-10 mb-8">
                          <p>{data.like} Likes</p>
                          <p>{data.like} Comments</p>
                      </div>
                      <p className="select-none line-clamp-[7]" dangerouslySetInnerHTML={{__html: data.caption.split("<br/>")[0]}}></p>
                  </div>
              </div>
            ))}
        </Carousel>}
    </div>)
}

export default Posts