import React, { useEffect, useState } from "react";
import { Guide } from "../../interface/GuideInterface";
import { getGuides } from "../../api/api";
import Carousel from "react-multi-carousel";
import GuideDetail from "../GuideDetail";
import { CustomLeftArrow, CustomRightArrow } from "../Additional/CarouselArrow";

const responsive = {
  ultraWideDesktop: {
    breakpoint: {max: 4000, min: 3000},
    items: 3
  },
  wideDesktop: {
    breakpoint: {max: 3000, min: 1280},
    items: 3
  },
  desktop: {
    breakpoint: {max: 1280, min: 1024},
    items: 3
  },
  tablet: {
    breakpoint: {max: 1024, min: 768},
    items: 2
  },
  mobile: {
    breakpoint: {max: 768, min: 0},
    items: 1
  }
};

interface ref {
  setRef: any
}

const Guides: React.FC<ref> = ({setRef}) => {
  
  const [guides, setGuides] = useState<Guide>({})
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false)
  
  useEffect(() => {
    getGuides()
      .then(data => setGuides(data))
  }, [])
  
  return (
    <div className="text-center text-khaki-900 py-10 px-2 md:px-6 lg:px-12 select-none">
      <p className="text-3xl font-semibold pb-6">
        Guides
      </p>
      {Object.keys(guides).length > 0 && <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable={false}
          customRightArrow={<CustomRightArrow onClick={undefined} />}
          customLeftArrow={<CustomLeftArrow onClick={undefined} />}
      >
        {Object.keys(guides).map((guideID) => {
          const guide = guides[guideID]
          return (
            <div
              key={guideID}
              className="relative"
              onClick={() => {
                console.log(1)
                setDetailDialogOpen(true)
                setSelectedItem(guide)
              }}
            >
              <img src={guide.cover_image} alt="" className="mx-auto h-96 w-72 border rounded-md object-cover"/>
              <div className="absolute bottom-0 inset-x-0 mx-auto w-72 px-2 py-4 bg-gradient-to-t from-black/60 from-20% to-transparent to-80% text-left text-white rounded-md">
                <p className="font-medium">{guide.num_items} Posts</p>
                <p className="text-lg font-semibold">{guide.title}</p>
              </div>
            </div>
          )
        })}
      </Carousel>}
      <GuideDetail
        isOpen={detailDialogOpen}
        item={selectedItem}
        closeModal={() => {
          setDetailDialogOpen(false)
          setTimeout(() => {
            setSelectedItem({})
          }, 250)
        }}
      />
      <a ref={setRef} />
    </div>
  )
}

export default Guides