import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { getToken } from "../api/api";

interface Form {
  open: boolean,
  setOpen: any
}

const DownloadForm = ({open, setOpen}: Form) => {
  
  const cancelButtonRef = useRef(null)
  
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  
  const handleClose = () => {
    setOpen(false)
    setName('')
    setEmail('')
  }
  
  const handleSubmit = () => {
    const data = new FormData()
    data.append('name', name)
    data.append('email', email)
    
    getToken(data).then(resp => {
      if (resp.ok) {
        window.open(`https://salatiga.shineryskin.com/api/download?token=${resp.data}`, '_self')
        handleClose()
      }
    })
  }
  
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={handleClose}>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-khaki-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#5E5E5E">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-khaki-900 h-full mt-2">
                        Mohon masukkan data diri Anda
                      </Dialog.Title>
                    </div>
                  </div>
                  <div>
                    <div className={'text-left pt-8'}>
                      <form onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                      }}>
                        <div className="relative z-0 mb-6 w-full group">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="block py-2.5 px-0 w-full text-sm text-khaki-900 bg-transparent border-0 border-b-2 border-khaki-300 appearance-none focus:outline-none focus:ring-0 focus:border-khaki-600 peer"
                            placeholder=" "
                            minLength={3}
                            required={true}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <label
                            htmlFor="name"
                            className="absolute text-sm text-khaki-600 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-khaki-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >Nama</label>
                        </div>
                        <div className="relative z-0 mb-6 w-full group">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="block py-2.5 px-0 w-full text-sm text-khaki-900 bg-transparent border-0 border-b-2 border-khaki-300 appearance-none focus:outline-none focus:ring-0 focus:border-khaki-600 peer"
                            placeholder=" "
                            required={true}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <label
                            htmlFor="email"
                            className="absolute text-sm text-khaki-600 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-khaki-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >Alamat Email</label>
                        </div>
                        <div className="bg-khaki-50 px-4 pb-3 sm:px-6 flex">
                          <button
                            type="submit"
                            className="w-full mx-auto text-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:w-auto sm:text-sm"
                          >
                            Unduh Katalog
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default DownloadForm