const Hero = () => {
  return (
    <>
      <div className="md:h-full w-full justify-center relative select-none h-auto mb-10">
        <div className="container-full grid md:grid-cols-2 gap-x-16 px-2 md:px-6 lg:px-12 mx-auto bg-khaki-100">
          <div className="my-10 md:my-auto text-center text-khaki-900">
            <p className="text-3xl lg:text-4xl xl:text-5xl whitespace-nowrap font-semibold tracking-widest underline underline-offset-4 xl:underline-offset-8 xl:mb-4 decoration-[#ffda56]">SHINERY AESTHETIC</p>
            <p className="text-lg lg:text-xl xl:text-2xl whitespace-nowrap tracking-[0.4rem] lg:tracking-[0.5rem] xl:tracking-[0.75rem]">BE SHINE, BE RADIANCE</p>
            <p className="my-10 px-2 leading-6 text-left">
              Menghadirkan beragam perawatan dan produk berkualitas untuk menjaga kesehatan dan kecantikan tubuh agar kondisinya senantiasa terjaga vitalitasnya.
            </p>
          </div>
          <div className="hidden md:block my-auto text-center h-[480px]">
            <img
              src="/Hero.jpg"
              className="w-min h-full object-cover rounded-xl mx-auto mt-10"
              draggable={false}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero