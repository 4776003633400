const Footer = () => {
  return (
    <>
      <div className="text-center bg-gray-100 text-khaki-900 py-10 px-2 lg:px-6 lg:px-12 select-none">
        <div className="container-full grid grid-cols-1 md:grid-cols-2 gap-y-10 gap-x-16 px-12 mx-auto">
          <div className="text-center text-khaki-900">
            <p className="text-xl md:text-2xl font-semibold tracking-widest underline underline-offset-4 decoration-[#ffda56]">SHINERY AESTHETIC</p>
            <p className="text-base md:text-lg tracking-[0.13rem] md:tracking-[0.2rem]">BE SHINE, BE RADIANCE</p>
            <p className="mt-10">Certified by</p>
            <div className="inline-flex gap-x-10 my-6">
              <img src="/mui.svg" className="w-24 h-24 object-center object-contain" alt={'MUI'}/>
              <img src="/bpom.png" className="w-24 h-24 object-center	object-contain" alt={'BPOM'}/>
            </div>
          </div>
          <div className="text-center text-khaki-900">
            <p className="text-lg font-semibold tracking-widest mb-1">Hubungi Kami</p>
            <ul className="list-none mx-auto text-left w-min">
              <li className="inline-flex whitespace-nowrap"><img src="/whatsapp-alt.svg" className="w-6 h-6 pr-2" alt={'Whatsapp Icon'}/>0878 34888811</li>
              <li className="inline-flex whitespace-nowrap"><img src="/phone-alt.svg" className="w-6 h-6 pr-2" alt={'Phone Icon'}/>0298 3434017</li>
              <li className="inline-flex"><img src="/instagram-alt.svg" className="w-6 h-6 pr-2" alt={'Instagram Icon'}/>shinery_aesthetic</li>
            </ul>
            <p className="text-lg font-semibold tracking-widest mt-10 mb-1">Alamat</p>
            <p className="">Jl. Senjoyo No.41B, Kutowinangun Kidul, Salatiga, Jawa Tengah</p>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 py-4 border-t-2 border-gray-200">
        <div className="flex justify-center md:justify-center items-center font-medium tracking-wide">
          &copy; Shinery Aesthetic 2021
        </div>
      </div>
    </>
  )
}

export default Footer