import React from "react";

const WAButton = () => {
  return (
    <div className="fixed z-10 bottom-0 right-0 mr-6 mb-6">
      <a href="https://wa.me/6287834888811" target="_blank">
        <button
          className="flex bg-white border border-gray-100 rounded-md py-2 px-4 font-semibold text-khaki-900 shadow-lg">
          <img src="/whatsapp.svg" className="w-8 h-8"/>
          <p className="pl-2 mt-1">Hubungi Kami</p>
        </button>
      </a>
    </div>
  )
}

export default WAButton