import { useEffect, useState } from "react";
import { getProducts } from "../../api/api";
import { IDRFormat } from "../../utils/helper";
import ProductDetail from "../ProductDetail";

export interface product {
  sku: string;
  name: string;
  price: number;
  description: string;
  photo: string;
  link: string;
}

const Products = () => {
  
  const [products, setProducts] = useState<product[]>([])
  const [detailOpen, setDetailOpen] = useState<boolean>(false)
  const [productDetail, setProductDetail] = useState<any>({})
  
  useEffect(() => {
    getProducts().then(res => {
      setProducts(res)
    })
  }, [])
  
  function closeModal() {
    setDetailOpen(false)
    setTimeout(() => {
      setProductDetail({})
    }, 250)
  }
  
  function openModal(item: product) {
    setDetailOpen(true)
    setProductDetail(item)
  }
  
  return (
    <div className="text-center text-khaki-900 py-10 px-2 md:px-6 lg:px-12 select-none">
      <p className="text-3xl font-semibold pb-6">
        Produk
      </p>
      <div className="container-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-5 md:gap-6 lg:gap-8 mx-2 my-2 pb-10">
        {products.map(product => (
          <div
            className="relative w-full h-full cursor-pointer"
            key={product.sku}
            onClick={() => {
              openModal(product)
            }}
          >
            <img
              src={product.photo}
              className="aspect-[4/5] object-cover w-full rounded-md bg-gray-100"
              draggable={false}
            />
            <p className="mt-2 text-xs md:text-sm lg:text-lg font-semibold capitalize">{product.name}</p>
            <p className="mt-1 text-xs md:text-sm lg:text-base font-medium">{IDRFormat(product.price)}</p>
          </div>
          ))}
      </div>
      <ProductDetail isOpen={detailOpen} item={productDetail} closeModal={closeModal} />
    </div>
  )
}

export default Products