const BrandAmb = () => {
  return (
    <div className="text-center bg-khaki-100 text-khaki-900 py-10 px-2 lg:px-6 lg:px-12 select-none">
      <div className="mx-auto w-80 h-80 sm:w-96 sm:h-96">
        <img src="/BrandAmb.png" className="object-center sm:object-none"/>
      </div>
      <p className="text-xl sm:text-3xl my-3 sm:my-6">
        "Klinik favorit paling affordable, sangat spesial, dan bersahabat!"
      </p>
      <p className="text-lg sm:text-2xl font-semibold tracking-widest underline underline-offset-4 decoration-[#ffda56]">
        OIK RAMADHANI
      </p>
      <p className="text-md sm:text-lg">Brand Ambassador Shinery Aesthetic</p>
    </div>
  )
}

export default BrandAmb